<template>
  <div class="login-bgr">
    <v-container fill-height>
      <v-layout align-center justify-center row>
        <v-flex xs12 align-self-start shrink>
          <status-io-message />
        </v-flex>
        <v-flex xs12>
          <v-card
            class="mx-auto my-12 pa-4 login-card"
            max-width="450"
            min-width="300"
            elevation="10"
          >
            <div class="d-flex justify-end align-center">
              <LocaleSwitcher color="primary" />
            </div>
            <h1 class="text-center mb-4">{{ $t('locationAlert') }}</h1>
            <i class="fal fa-map-marker-alt icon" />
            <h2 class="text-center mb-16">{{ $t('login') }}</h2>
            <v-form
              ref="formInstance"
              :disabled="loading"
              @submit.prevent="!askForPinCode ? login() : verifyPinCode()"
            >
              <template v-if="!askForPinCode">
                <v-text-field
                  v-model="username"
                  autofocus
                  :rules="usernameRules"
                  validate-on-blur
                  type="text"
                  :label="$t('email')"
                  autocomplete="username"
                  @input="loginErrorMessage = ''"
                />
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  validate-on-blur
                  :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('password')"
                  counter
                  autocomplete="current-password"
                  @click:append="showPassword = !showPassword"
                  @input="loginErrorMessage = ''"
                />
              </template>
              <template v-else>
                <label for="otp-input">{{ $t('otpSms') }}</label>

                <v-otp-input
                  id="otp-input"
                  ref="otpInput"
                  v-model="pinCode"
                  type="number"
                  length="5"
                  autocomplete="off"
                  hide-spin-buttons
                  @finish="verifyPinCode()"
                  @input="loginErrorMessage = ''"
                />
                <v-progress-linear v-if="loading" indeterminate color="primary" class="mt-2" />
              </template>
              <v-alert
                dense
                :style="{ opacity: loginErrorMessage.length > 0 ? 1 : 0 }"
                type="error"
              >
                {{ loginErrorMessage }}
              </v-alert>
              <v-card-actions v-if="!askForPinCode" class="mt-4">
                <v-spacer />
                <v-btn color="primary" :loading="loading" rounded block type="submit">
                  {{ $t('login') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { maxLength, minLength, required } from '@/util/formRules';
import StatusIoMessage from '@/components/StatusIoMessage.vue';

export default {
  name: 'Login',

  components: {
    LocaleSwitcher,
    StatusIoMessage,
  },

  data() {
    return {
      username: '',
      password: '',
      pinCode: '',
      loading: false,
      showPassword: false,
      askForPinCode: false,
      loginErrorMessage: '',
    };
  },

  computed: {
    usernameRules() {
      const field = this.$t('email');
      return [required(field), minLength(field, 5), maxLength(field, 80)];
    },

    passwordRules() {
      const field = this.$t('password');
      return [required(field), minLength(field, 5), maxLength(field, 64)];
    },
  },

  methods: {
    async login() {
      if (!this.$refs.formInstance.validate()) {
        return;
      }

      this.loading = true;
      const body = {
        username: this.username,
        password: this.password,
      };

      try {
        this.loginErrorMessage = '';
        const result = await this.$store.dispatch('user/login', body);
        if (result.status == 200) {
          if (!result.data.challengeSmsPinCode) {
            this.$router.push({ name: 'Dashboard' });
          } else {
            this.askForPinCode = true;
            this.$nextTick(() => {
              const firstInput = this.$refs.otpInput.$el.querySelector('input');
              firstInput?.focus();
            });
          }
        } else if (result.status == 401) {
          this.loginErrorMessage = this.$t('loginError');
        } else if (result.status == 403) {
          this.loginErrorMessage = this.$t('noAccess');
        } else if (result.status == 423) {
          this.loginErrorMessage = this.$t('userLocked');
        }
      } catch (error) {
        alert(error);
      }

      this.loading = false;
    },
    async verifyPinCode() {
      if (!this.$refs.formInstance.validate()) {
        return;
      }

      this.loading = true;
      const body = {
        username: this.username,
        password: this.password,
        pinCode: this.pinCode,
      };

      try {
        const result = await this.$store.dispatch('user/verifyPinCode', body);
        if (result.status == 200) {
          this.$router.push({ name: 'Dashboard' });
        } else if (result.status === 400 || result.status === 401) {
          this.loginErrorMessage = this.$t('verifyPincodeError');
        } else if (result.status == 403) {
          this.loginErrorMessage = this.$t('noAccess');
        } else if (result.status == 423) {
          this.loginErrorMessage = this.$t('userLocked');
        }
      } catch (error) {
        alert(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
h2 {
  color: #d32f2f;
}
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}
.login-bgr {
  position: relative;
  height: 100vh;
  background: url('../assets/images/bgr.jpg');
  background-size: cover;
  overflow: auto;
}
.icon {
  font-size: 5em;
  text-align: center;
  display: block;
  padding: 16px 0;
  color: #d32f2f;
}
.login-card {
  background-color: #ffffffdb;
}
</style>
